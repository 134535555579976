import React from "react"
import {Helmet} from 'react-helmet'
import SEO from '../components/seo'
import Link from "gatsby-link"
import { graphql } from 'gatsby'
import Layout from "../layouts/layout-scroll"
import Image from 'gatsby-image'

export default ({data}) => {
    return (
        <Layout>
          <Helmet htmlAttributes={{ class:'collections fixed' }} />
          <SEO title="Travel collections photos"
            description={data.site.siteMetadata.descriptions.collections} />
          <h1>Travel collections</h1>
          <div>
            <p style={{ textAlign: 'center' }}>
                {data.allMarkdownRemark.totalCount} collections
            </p>
            {data.allMarkdownRemark.edges.map(({node}) =>
                <div key={`${node.fields.slug}`}
                      style={{
                        position: 'relative',
                        width: '100%',
                        height: '200px',
                        marginTop: '1em',
                      }}>
                      <Link
                            to={node.fields.slug}
                        >
                  <Image
                    fluid={node.frontmatter.cover.childImageSharp.fluid}
                    style={{
                      width: '100%',
                      height: '100%',
                      position: 'absolute',
                      borderRadius: `0.5em`,
                    }}
                  />
                  <div
                    style={{
                      width: '100%',
                      height: '100%',
                      position: 'absolute',
                      background: 'linear-gradient(transparent, 70%, #333C)',
                      borderRadius: `0.5em`,
                    }}
                  />
                    <div style={{
                      textDecoration: `none`,
                      color: `inherit`,
                      width: '100%',
                      position: 'absolute',
                      bottom: '1em',
                      left: '1em',
                    }}
                    >

                        <h3 style={{
                          marginBottom: '0.5rem',
                          color: '#fff'
                        }}
                        >
                            {node.frontmatter.title}
                        </h3>
                        <span style={{
                          color: "#BBB",
                        }}
                        >{node.frontmatter.date}</span>
                        <p
                        style={{
                          padding: 0,
                          margin: 0
                        }}>
                            {node.frontmatter.subtitle}
                        </p>
                      </div>
                </Link>
              </div>
            )}
          </div>
        </Layout>
    )
}

export const query = graphql `
  query CollectionsQuery {
    site {
      siteMetadata {
        title
        descriptions {
          collections
        }
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }, filter: { frontmatter: { type: { eq: "collection"}, visible: { eq: true}}}) {
      totalCount
      edges {
        node {
          frontmatter {
            type
            title
            subtitle
            date(formatString: "MMMM, YYYY")
            background
            cover {
              childImageSharp {
                fixed {
                  src
                  srcSet
                  width
                  height
                }
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`
